import { createClient } from 'contentful'
import {
  IAbout,
  IAboutFields,
  IContactsFields,
  IGalleryFields,
  INews,
  INewsFields,
} from './@types/contentful'
import { EntrySkeletonType, EntrySys } from 'contentful/dist/types/types'
import { Asset } from 'contentful-management/dist/typings/export-types'
import { BLOCKS } from '@contentful/rich-text-types'

const useContentful = () => {
  const client = createClient({
    space: 'd6lxe9p0fs19',
    accessToken: 'Ymlb1Jln6a41NROZzQ42dh0UsG-nBJg0BDLn6lg8jFQ',
  })

  const Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        return (
          <img
            src={`https:${node.data.target.fields.file.url}`}
            alt={node.data?.target?.fields?.title}
            loading="lazy"
            className="news-article__img"
          />
        )
      },
    },
  }

  const getNews = async (page: number, limit: number) => {
    try {
      const entiries = await client.getEntries<EntrySkeletonType<INewsFields, string>>({
        content_type: 'news',
        select: ['fields'],
        skip: page,
        limit: limit,
        // @ts-ignore
        order: '-fields.date',
      })

      return entiries
    } catch (error) {
      console.log(error)
    }
  }
  const getNewsArticle = async (id: string) => {
    try {
      const entiry = await client.getEntry<EntrySkeletonType<INewsFields, string>>(id)
      console.log(entiry)
      return entiry.fields
    } catch (error) {
      console.log(error)
    }
  }
  // get gallery images
  const getImages = async () => {
    try {
      const entiries = await client.getEntries<EntrySkeletonType<IGalleryFields, string>>({
        content_type: 'gallery',
      })
      const sanitizedEntries = entiries.items.map((item) => item.fields.img as Asset)
      return sanitizedEntries
    } catch (error) {
      console.log(error)
    }
  }

  //get about content
  const getAboutData = async () => {
    try {
      const entiries = await client.getEntries<EntrySkeletonType<IAboutFields, string>>({
        content_type: 'about',
        // select: ['fields'],
        // 'fields.sectionNumber': 7,
      })
      const sanitizedEntries = entiries.items.map((item) => item.fields)
      return sanitizedEntries
    } catch (error) {
      console.log(error)
    }
  }

  //get contacts
  const getContacts = async () => {
    try {
      const entiries = await client.getEntries<EntrySkeletonType<IContactsFields, string>>({
        content_type: 'contacts',
        // @ts-ignore
        order: 'fields.id',
      })
      const sanitizedEntries = entiries.items.map((item) => item.fields)
      // .sort((a, b) => a.id - b.id)
      return sanitizedEntries
    } catch (error) {
      console.log(error)
    }
  }
  return { Options, getNews, getNewsArticle, getImages, getAboutData, getContacts }
}

export default useContentful
