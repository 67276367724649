import { FC } from 'react'

type ParralaxType = {
  label?: string
}

const Parralax: FC<ParralaxType> = ({ label }) => {
  return <div className="parralax">{label && <h4 className="parralax__title">{label}</h4>}</div>
}

export default Parralax
