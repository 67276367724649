import React, { FC, useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/main/logo.svg'
import { LinkList, MAIN_ROUTE } from '../routes'

const Header: FC = () => {
  const [sticky, setSticky] = useState(false)
  const [burgerActive, setBurgerActive] = useState(false)

  const handleClose = () => setBurgerActive(false)

  document.body.style.overflow = burgerActive ? 'hidden' : 'unset'

  const nav = LinkList.map((e, i) => (
    <NavLink to={e.path} key={i} className="menu__link" onClick={handleClose}>
      {e.label}
    </NavLink>
  ))
  //open & close menu on swipe
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  const minSwipeDistance = 100

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null)
    setTouchStart(e.nativeEvent.touches[0].pageX)
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.nativeEvent.touches[0].pageX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    if (touchEnd - touchStart > minSwipeDistance) {
      setBurgerActive(false)
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY >= 100)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <header className={`header ${sticky && 'sticky'}`}>
      <nav className="nav">
        <Link to={MAIN_ROUTE} className="nav-logo">
          <Logo />
          <h2 className="nav-logo__title">ОАО Радонежское</h2>
        </Link>
        <div
          className={`menu ${burgerActive && 'menu_active'} `}
          onTouchStart={(e) => burgerActive && onTouchStart(e)}
          onTouchMove={(e) => burgerActive && onTouchMove(e)}
          onTouchEnd={(e) => burgerActive && onTouchEnd()}
        >
          {nav}
        </div>
        <div className="nav-bars" onClick={() => setBurgerActive(!burgerActive)}>
          <span className={`nav-bars__item ${burgerActive && 'active'}`}></span>
        </div>
      </nav>
    </header>
  )
}
export default Header
