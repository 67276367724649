import { EntryCollection, EntrySkeletonType } from 'contentful'
import { Asset } from 'contentful-management'
import { FC, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Gallery from 'react-photo-gallery'
import Parralax from '../components/parralax'
import useContentful from '../useContentful'

interface img {
  src: string
  width: number
  height: number
  key: string
}

const Gallerya: FC = () => {
  const { getImages } = useContentful()
  const [photos, setPhotos] = useState<img[] | null>()
  useEffect(() => {
    getImages().then((res) => {
      if (res) {
        console.log(
          res.map((e) => ({
            src: e.sys.id,
          })),
        )
        setPhotos(
          res.map((e) => {
            if (typeof e.fields.file.url === 'string' && e.sys.id) {
              return {
                src: e.fields.file.url,
                width: 4,
                height: 3,
                key: e.sys.id,
              }
            }
            return { src: '', width: 1, height: 1, key: e.sys.id }
          }),
        )
      }
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Галерея</title>
        <link rel="canonical" href="https://radonezhskoe.by/gallery" />
        <meta
          name="description"
          content="ОАО РАДОНЕЖСКОЕ Корчицы: галлерея, фото, новости, сельское хозяйство кобрин."
        />
      </Helmet>
      <section className="gallery">
        <Parralax label="Галерея" />
        {photos && <Gallery photos={photos} />}
      </section>
    </>
  )
}

export default Gallerya
