import { FC } from 'react'
import { Link } from 'react-router-dom'

interface SliderItemProps {
  path: string
  img: string
}

const SliderFooterItem: FC<SliderItemProps> = ({ path, img }) => {
  return (
    <Link to={path} className="footer-slide">
      <img
        className="footer-slide__img"
        src={require(`../assets/footer/${img}`)}
        alt=""
        loading="lazy"
      />
    </Link>
  )
}

export default SliderFooterItem
