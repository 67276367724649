import { FC } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface CarouselProps {
  settings?: Settings
  slideList?: React.ReactNode[]
}

const Carousel: FC<CarouselProps> = ({ settings, slideList }) => {
  return <Slider {...settings}>{slideList}</Slider>
}

export default Carousel
