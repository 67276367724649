import React, { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Parralax from '../components/parralax'
import useContentful from '../useContentful'
import { INewsFields } from '../@types/contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'react-router-dom'

const NewsArticle: FC = () => {
  const [article, setArticle] = useState<INewsFields | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { id } = useParams<{ id: string }>()
  const { getNewsArticle, Options } = useContentful()

  useEffect(() => {
    setLoading(true)
    id &&
      getNewsArticle(id).then((res) => {
        console.log(res)
        res && setArticle(res)
      })
    setLoading(false)
  }, [])
  console.log(article?.text)
  //fix images in text loaded
  return (
    <>
      {/* {!loading && article?.items[0] && ( */}
      <Parralax label={article?.title} />
      <article className="news-article wrapper">
        <Link to="/news" className="back-btn">
          Назад
        </Link>
        {!!article && (
          <>
            <h4 className="news-article__header">{article?.title}</h4>
            <span className="date-icon"></span>
            <span className="news-article__date">{article?.date}</span>
            {article?.img?.map((e) => (
              <img
                key={`${e.fields.file?.url}`}
                loading="lazy"
                className="news-article__img"
                src={`${e.fields.file?.url}`}
                alt=""
              ></img>
            ))}
            <div className="news-article__text">
              {documentToReactComponents(article.text, Options)}
            </div>
          </>
        )}
      </article>
      {/* )} */}
    </>
  )
}

export default NewsArticle
