import { FC, lazy, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
// import About from './pages/about'
import MainLayout from './layouts/MainLayout'
import Gallerya from './pages/gallery'
import NewsArticle from './pages/newsArticle'
// import Main from './pages/main'
import { MAIN_ROUTE, ABOUT_ROUTE, NEWS_ROUTE, CONTACTS_ROUTE, GALLERY_ROUTE } from './routes'
import './scss/app.scss'

const Main = lazy(() => import(/* webpackChunkName: "main-page" */ './pages/main'))
const About = lazy(() => import(/* webpackChunkName: "about" */ './pages/about'))
const News = lazy(() => import(/* webpackChunkName: "news" */ './pages/news'))
const Contacts = lazy(() => import(/* webpackChunkName: "contacts" */ './pages/contacts'))
const NotFound = lazy(() => import(/* webpackChunkName: "404" */ './pages/404'))

const App: FC = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="*" element={<NotFound />} />
        <Route path={MAIN_ROUTE} element={<Main />} />
        <Route path={`${ABOUT_ROUTE}/:id/*`} element={<About />} />
        <Route path={NEWS_ROUTE} element={<News />} />
        <Route path={`${NEWS_ROUTE}/:id`} element={<NewsArticle />} />
        <Route path={CONTACTS_ROUTE} element={<Contacts />} />
        <Route path={GALLERY_ROUTE} element={<Gallerya />} />
      </Route>
    </Routes>
  )
}
export default App
