export const MAIN_ROUTE = '/'
export const ABOUT_ROUTE = '/about'
export const NEWS_ROUTE = '/news'
export const CONTACTS_ROUTE = '/contacts'
export const GALLERY_ROUTE = '/gallery'
export const ENTRY_ROUTE = '/'
//nested routes
export const ABOUT_MAIN = '/info'
export const ABOUT_SOCIAL = '/social'
export const ABOUT_POCHET = '/pochet'
export const ABOUT_REWARD = '/reward'
export const ABOUT_SPORT = '/sport'
export const ABOUT_YOUNG = '/young'
//other links
export const APP = 'https://app.radonezhskoe.by/'
interface NavLink {
  label: string
  path: string
}

export const LinkList: NavLink[] = [
  {
    label: 'Главная',
    path: MAIN_ROUTE
  },
  {
    label: 'О Хозяйстве',
    path: `${ABOUT_ROUTE}${ABOUT_MAIN}`
  },
  {
    label: 'Новости',
    path: NEWS_ROUTE
  },
  {
    label: 'Контакты',
    path: CONTACTS_ROUTE
  },
  {
    label: 'Галерея',
    path: GALLERY_ROUTE
  },
  {
    label: 'Приложение',
    path: APP
  }
  // {
  //   label: 'Войти',
  //   path: ENTRY_ROUTE,
  // },
]

//nest links list

export const NestedLinkList: NavLink[] = [
  {
    label: 'О хозяйстве',
    path: `${ABOUT_ROUTE}${ABOUT_MAIN}`
  },
  {
    label: 'Социальная сфера',
    path: `${ABOUT_ROUTE}${ABOUT_SOCIAL}`
  },
  {
    label: 'Доска почета',
    path: `${ABOUT_ROUTE}${ABOUT_POCHET}`
  },
  {
    label: 'Достижения и награды',
    path: `${ABOUT_ROUTE}${ABOUT_REWARD}`
  },
  {
    label: 'Здоровый образ жизни',
    path: `${ABOUT_ROUTE}${ABOUT_SPORT}`
  },
  {
    label: 'Организация работы с молодежью',
    path: `${ABOUT_ROUTE}${ABOUT_YOUNG}`
  }
]
