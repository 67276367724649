import { FC } from 'react'
import { LazyLoadTypes } from 'react-slick'
import Carousel from './slider'
import SliderFooterItem from './sliderFooterItem'
const settings = {
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  lazyLoad: 'progressive' as LazyLoadTypes,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  centerPadding: '60px',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const slides = [
  {
    img: 'god_mira_gotovo.png',
    path: 'https://president.gov.by/ru/documents/ukaz-no-1-ot-1-yanvarya-2023-g',
  },
  {
    img: 'president.jpg',
    path: 'https://president.gov.by/ru',
  },
  {
    img: 'pravo.png',
    path: 'https://pravo.by/',
  },
  {
    img: 'ministerstvo.jpg',
    path: 'http://www.mshp.gov.by/ru/',
  },
  {
    img: 'kobrin.jpeg',
    path: 'http://www.kobrin.brest-region.gov.by/ru',
  },
  {
    img: 'kobtin-city.jpeg',
    path: 'https://www.kobrincity.by/',
  },
  {
    img: 'kobrin-soc.jpeg',
    path: 'http://spc.kobrin.edu.by/',
  },
  // {
  //   img: '../assets/footer/kobtin-city.jpeg',
  //   path: 'https://president.gov.by/',
  // },
]
const Footer: FC = () => {
  const slideList = slides.map((e) => <SliderFooterItem key={e.path} {...e} />)
  return (
    <footer className="footer">
      <div className="footer-slider">
        <div className="wrapper">
          <Carousel settings={settings} slideList={slideList} />
        </div>
      </div>
      <div className="footer-info">
        <span className="footer-info__text">ОАО «Радонежское»</span>
        <span className="footer-info__text">УНП 200224879</span>
        <span className="footer-info__text">Директор</span>
        <span className="footer-info__text">Владислав Павлович БАЛАБУШКО</span>
      </div>
    </footer>
  )
}
export default Footer
