import { FC, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/footer'
import Header from '../components/header'
import Spinner from '../components/spinner'

const MainLayout: FC = () => {
  const location = useLocation().pathname
  return (
    <>
      <Header />
      <Suspense
        fallback={
          <>
            {location !== '/' && <div className="parralax"></div>}
            <div className="loader-wrapper">
              <Spinner />
            </div>
          </>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </>
  )
}

export default MainLayout
